/* body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #e9ecef;
}

.container {
  font-family: Source Sans Pro, Arial, sans-serif;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.spinner {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top-color: #3498db;
  display: block;
  height: 120px;
  width: 120px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.main-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.confirmation-message {
  display: flex;
  flex-direction: column;  
  align-items: center;  
  justify-content: center;   
  text-align: center;     
  color: grey;
  width: 100%;
  margin-top: 20px;
  background-color: lightblue; 
}

.confirmation-message.new-signature {
  min-height: 120px;
}

.confirmation-icon.new-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
}

.confirmation-icon {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.text-success {
  font-size: 18px;
  color: grey;
  margin-top: 10px;
  text-align: center; 
  width: 100%; 
  background-color: lightgreen;  
} */

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9ecef;
}

.main-wrapper {
  display: block; /* Если хотите, чтобы элементы шли строго один за другим */
  text-align: center;
}

.overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.spinner {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top-color: #3498db;
  display: block;
  height: 120px;
  width: 120px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}

.confirmation-message {
  margin: 10px auto; /* Центрирование и добавление небольшого отступа между элементами */
  width: 100%; /* Убедитесь, что элементы не выходят за пределы экрана */
}

.confirmation-icon.new-icon {
  width: 120px; /* Устанавливаем фиксированную ширину для новой иконки */
  height: 120px; /* Устанавливаем фиксированную высоту для новой иконки */
  margin: 10px auto;
}

.confirmation-icon {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 300px; /* Устанавливаем минимальную ширину изображения */
}

.text-success {
  font-size: 18px;
  color: grey;
  margin: 10px auto; /* Добавляем вертикальные отступы для текста */
  animation: fadeIn 1s ease-in-out; /* Анимация появления текста */
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


